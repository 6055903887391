import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Global.css'; // Import the CSS file

const StudentDashboard = () => {
  return (
    <div>
      <title>Student Dashboard</title>
      <header>
        <nav>
          <ul>
            <li><Link to="/home">Home</Link></li>
            <li><Link to="/settings">Settings</Link></li>
            <li><Link to="/login">Logout</Link></li>
            <div className="dashboard-title">
              DASHBOARD
            </div>
          </ul>
        </nav>
      </header>
      <div className="dashboard-container">
        <div className="dashboard-container">
          <div className="welcome-text">
            <h2>Welcome, Student!</h2>
          </div>
          <div className="dashboard-links">
            <ul className="circle-container">
              <li>
                <div className="circle">
                  <Link to="/academic-program-overview">Academic Program Overview</Link>
                </div>
              </li>
              <li>
                <div className="circle">
                  <Link to="/student1">Course Catalog</Link>
                </div>
              </li>
              <li>
                <div className="circle">
                  <Link to="/course-materials">Course Materials</Link>
                </div>
              </li>
              <li>
                <div className="circle">
                  <Link to="/student2">Exam and Assessment Portal</Link>
                </div>
              </li>
              <li>
                <div className="circle">
                  <Link to="/student3">Student Progress Tracking</Link>
                </div>
              </li>
            </ul>
            <Link to="/chat" className="chat-button">
              <img src="/Images/chat-icon.png" alt="Chat" className="chat-icon" />
            </Link>
          </div>
        </div>
      </div>
      <footer>
        <div className="footer-links">
          <ul>
            <li><Link to="/privacy">Privacy Policy</Link></li>
            <li><Link to="/faq">FAQ</Link></li>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default StudentDashboard;
