import React, { useState, useEffect } from 'react';
//import { Link } from 'react-router-dom';
import './Global.css'; // Make sure to import your Global.css file


const Int1 = () => {
  const [courses, setCourses] = useState([]);
  const [newCourseName, setNewCourseName] = useState('');
  const [isAddingCourse, setIsAddingCourse] = useState(false);
  const [updatingCourseId, setUpdatingCourseId] = useState(null);
  const [updatedCourseName, setUpdatedCourseName] = useState('');

  useEffect(() => {
    // Fetch courses from the server or an API on component mount
    // For demonstration purposes, using a static array
    const fetchData = async () => {
      const data = [
        { id: 1, name: 'Course A' },
        { id: 2, name: 'Course B' },
        // ... other courses
      ];

      setCourses(data);
    };

    fetchData();
  }, []);

  const addCourse = () => {
    setIsAddingCourse(true);
  };

  const handleNewCourseChange = (e) => {
    setNewCourseName(e.target.value);
  };

  const submitNewCourse = () => {
    const newCourse = {
      id: courses.length + 1,
      name: newCourseName,
    };

    setCourses([...courses, newCourse]);
    setNewCourseName('');
    setIsAddingCourse(false);
  };

  const cancelAddCourse = () => {
    setIsAddingCourse(false);
    setNewCourseName('');
  };

  const updateCourse = (id) => {
    setUpdatingCourseId(id);
    const courseToUpdate = courses.find((course) => course.id === id);
    setUpdatedCourseName(courseToUpdate.name);
  };

  const handleUpdatedCourseChange = (e) => {
    setUpdatedCourseName(e.target.value);
  };

  const submitUpdatedCourse = () => {
    const updatedCourses = courses.map((course) =>
      course.id === updatingCourseId ? { ...course, name: updatedCourseName } : course
    );

    setCourses(updatedCourses);
    setUpdatingCourseId(null);
    setUpdatedCourseName('');
  };

  const cancelUpdateCourse = () => {
    setUpdatingCourseId(null);
    setUpdatedCourseName('');
  };

  const deleteCourse = (id) => {
    const updatedCourses = courses.filter((course) => course.id !== id);
    setCourses(updatedCourses);
  };

  return (
    <div style={containerStyle}>
      <h2 style={headerStyle}>Course and Program Management</h2>
      {isAddingCourse ? (
        <div style={formContainerStyle}>
          <label htmlFor="newCourseName" style={labelStyle}>
            New Course Name:
          </label>
          <input
            type="text"
            id="newCourseName"
            value={newCourseName}
            onChange={handleNewCourseChange}
            style={inputStyle}
          />
          <button onClick={submitNewCourse} style={buttonStyle}>
            Add
          </button>
          <button onClick={cancelAddCourse} style={buttonStyle}>
            Cancel
          </button>
        </div>
      ) : (
        <button onClick={addCourse} style={buttonStyle}>
          Add Course
        </button>
      )}
      <ul style={listStyle}>
        {courses.map((course) => (
          <li key={course.id} style={listItemStyle}>
            {updatingCourseId === course.id ? (
              <div>
                <input
                  type="text"
                  value={updatedCourseName}
                  onChange={handleUpdatedCourseChange}
                  style={inputStyle}
                />
                <button onClick={submitUpdatedCourse} style={buttonStyle}>
                  Update
                </button>
                <button onClick={cancelUpdateCourse} style={buttonStyle}>
                  Cancel
                </button>
              </div>
            ) : (
              <div>
                {course.name}
                <button onClick={() => updateCourse(course.id)} style={buttonStyle}>
                  Update
                </button>
                <button onClick={() => deleteCourse(course.id)} style={buttonStyle}>
                  Delete
                </button>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

// Inline styles
const containerStyle = {
  fontFamily: 'Arial, sans-serif',
  background: 'linear-gradient(45deg, rgb(130, 45, 52), #f3f7f6)',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#fff',
};

const headerStyle = {
  fontSize: '24px',
  marginBottom: '20px',
};

const formContainerStyle = {
  marginBottom: '20px',
};

const labelStyle = {
  display: 'block',
  fontSize: '16px',
  marginBottom: '5px',
  color: '#fff',
};

const inputStyle = {
  width: '200px',
  padding: '8px',
  margin: '5px 0',
  border: '2px solid #6c272e',
  borderRadius: '5px',
  fontSize: '16px',
  outline: 'none',
};

const buttonStyle = {
  backgroundColor: '#761031',
  color: '#fff',
  border: 'none',
  padding: '10px 20px',
  borderRadius: '5px',
  cursor: 'pointer',
  fontSize: '16px',
  margin: '0 5px',
  transition: 'background-color 0.3s',
};

const listStyle = {
  listStyle: 'none',
  padding: '0',
};

const listItemStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid #fff',
  padding: '10px 0',
};

export default Int1;

