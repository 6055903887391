import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Home from './index';
import About from './about';
import ContactUs from './contact';
import RegistrationPage from './registration';
import LoginPage from './Login';
import PrivacyPolicy from './privacy';
import FAQs from './faq';
import ChatPage from './chat';
import Settings from './settings';
import Notification from './notification';
import StudentDashboard from './StudentDB';
import Student1 from './Stu1';
import Student2 from './Stu2';
import Student3 from './Stu3';
import InstructorDashboard from './InstructorDB';
import Instructor1 from './int1';
import Instructor2 from './int2';
import Instructor3 from './int3';
import Instructor4 from './int4';
import AdminDashboard from './AdminDB';
import Admin1 from './Admin1';
import Admin2 from './Admin2';
import Admin3 from './Admin3';
import Admin4 from './Admin4';
import ProgramCoordinatorDashboard from './PCDB';
import Program1 from './PC1';
import Program2 from './PC2';
import Program3 from './PC3';
import QualityAssuranceOfficerDashboard from './QAODB';
import Quality1 from './QAO1';
import Quality2 from './QAO2';
import Quality3 from './QAO3';
import { BrowserRouter as Router } from 'react-router-dom'; // Import BrowserRouter or other router component from 'react-router-dom'

const Customroutes = () => {
  return (
    <Router>
      <Routes>
      <Route path="/" exact element={<Home/>} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/registration" element={<RegistrationPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/faq" element={<FAQs />} />
      <Route path="/chat" element={<ChatPage />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/notification" element={<Notification/>}/>
      <Route path="/student-dashboard" element={<StudentDashboard />} />
      <Route path="/student1" element={<Student1 />} />
      <Route path="/student2" element={<Student2 />} />
      <Route path="/student3" element={<Student3 />} />
      <Route path="/instructor-dashboard" element={<InstructorDashboard />} />
      <Route path="/instructor1" element={<Instructor1 />} />
      <Route path="/instructor2" element={<Instructor2 />} />
      <Route path="/instructor3" element={<Instructor3 />} />
      <Route path="/instructor4" element={<Instructor4 />} />
      <Route path="/admin-dashboard" element={<AdminDashboard />} />
      <Route path="/admin1" element={<Admin1 />} />
      <Route path="/admin2" element={<Admin2 />} />
      <Route path="/admin3" element={<Admin3 />} />
      <Route path="/admin4" element={<Admin4 />} />
      <Route path="/pc-dashboard" element={<ProgramCoordinatorDashboard />} />
      <Route path="/pc1" element={<Program1 />} />
      <Route path="/pc2" element={<Program2 />} />
      <Route path="/pc3" element={<Program3 />} />
      <Route path="/qao-dashboard" element={<QualityAssuranceOfficerDashboard />} />
      <Route path="/qao1" element={<Quality1 />} />
      <Route path="/qao2" element={<Quality2 />} />
      <Route path="/qao3" element={<Quality3 />} />
            </Routes>
            </Router>
  );
};

export default Customroutes;
