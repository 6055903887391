import React from 'react';
import { Link } from 'react-router-dom';
import './Global.css'; // Make sure to import your Global.css file

const Admin2 = () => {
  return (
    <div>
      <title>Admin Dashboard</title>
      <link rel="stylesheet" type="text/css" href="Global.css" />
      <header>
        <nav>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/settings">Settings</Link></li>
            <li><Link to="/login">Logout</Link></li>
            <div className="dashboard-title">
              User Account Management
            </div>
          </ul>
        </nav>
      </header>
      <div className="dashboard-container">
        <div className="dashboard-container">
          <div className="dashboard-links">
            <ul className="circle-container">
              <li>
                <div className="circle">
                  <Link to="#">Users</Link>
                </div>
              </li>
              <li>
                <div className="circle">
                  <Link to="#">Manage Accounts</Link>
                </div>
              </li>
              <li>
                <div className="circle">
                  <Link to="#">Manage Registration</Link>
                </div>
              </li>
              <li>
                <div className="circle">
                  <Link to="#">Manage Enrollment</Link>
                </div>
              </li>
            </ul>
            <Link to="#" className="chat-button">
              <img src="Images/chat-icon.png" alt="Chat" className="chat-icon" />
            </Link>
          </div>
        </div>
      </div>
      <footer>
        <div className="footer-links">
          <ul>
            <li><Link to="/privacy">Privacy Policy</Link></li>
            <li><Link to="/faqs">FAQ</Link></li>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default Admin2;

