// Header.js
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './styles/astyle.css'; // Import the CSS file for styles

const Home = () => {
    return (
        <section className="header">

            
            {/* Navigation */}
            <nav>
                <Link className="logo-link" to="/"><img alt="Logo" className="logo" src="/Images/logo2.png" /></Link>
                <div className="nav-links">
                    <ul>
                        <li><a href="https://axp1263.uta.cloud/blog/">WEBDATAINSIGHTS</a></li>
                        <li><Link to="/">HOME</Link></li>
                        <li><Link to="/about">ABOUT US</Link></li>
                        <li><Link to="/contact">CONTACT US</Link></li>
                        <li><Link to="/login">LOG IN</Link></li>
                        <li><Link to="/Registration">REGISTER</Link></li>
                    </ul>
                </div>
            </nav>

            {/* Text Box */}
            <div className="text-box">
                <h1>Welcome to Eduneeds</h1>
                <p>This is where all your academic needs will be fulfilled in one shot.</p>
            </div>
        </section>
    );
}

export default Home;