import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const RegistrationPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    role: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://axp1263.uta.cloud/backend/register.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();

      if (response.ok) {
        navigate('/');
        alert("Registration successful, please login to continue");
      } else {
        alert(data.error);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    }
  };

  const containerStyles = {
    fontFamily: 'Arial, sans-serif',
    background: 'linear-gradient(45deg, rgb(130, 45, 52), #f3f7f6)',
    height: '100vh',
    margin: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const registrationContainerStyles = {
    background: '#fff',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    padding: '20px',
    width: '300px',
    textAlign: 'center',
  };

  const inputStyles = {
    width: '90%',
    padding: '10px',
    margin: '10px 10px',
    border: '2px solid #db3474',
    borderRadius: '5px',
    fontSize: '16px',
    outline: 'none',
  };

  const buttonStyles = {
    backgroundColor: '#761031',
    color: '#fff',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '18px',
    transition: 'background-color 0.3s',
  };

  const linkStyles = {
    color: '#000',
    textDecoration: 'none',
  };

  return (
    <div style={containerStyles}>
      <title>Registration Page</title>
      <form id="Registration" style={registrationContainerStyles} onSubmit={handleSubmit}>
        <h2 style={{ color: '#db344d' }}>Register</h2>
        <div className="input-container">
          <input type="text" id="Name" name="name" placeholder="Name" style={inputStyles} onChange={handleChange} required />
        </div>
        <div className="input-container">
          <input type="email" id="email" name="email" placeholder="Email" style={inputStyles} onChange={handleChange} required />
        </div>
        <div className="input-container">
          <input type="password" id="password" name="password" placeholder="Password" style={inputStyles} onChange={handleChange} required />
        </div>
        <div className="input-container">
          <label htmlFor="roles" style={{ color: '#db344d' }}>Select Role:</label>
          <select id="roles" name="role" onChange={handleChange} style={inputStyles}>
            <option value="">Select a Role</option>
            <option value="student">Student</option>
            <option value="instructor">Instructor</option>
            <option value="admin">Administrator</option>
            <option value="pc">Program Coordinator</option>
            <option value="qao">Quality Assurance Officer</option>
          </select>
        </div>
        <div className="input-container">
          <button type="submit" style={buttonStyles}>Register</button>
        </div>
        <Link to="/" style={linkStyles}>Go back to Home</Link>
      </form>
    </div>
  );
};

export default RegistrationPage;
