import React from 'react';
import { Link } from 'react-router-dom';
import './styles/chat.css'; // Make sure to adjust the path based on your project structure

const ChatPage = () => {
  return (
    <div>
      <title>Chat Page</title>
      <link rel="stylesheet" type="text/css" href="chat.css" />
      <div className="chat-container">
        <div className="chat-box">
          <div className="chat-header">
            <h3>Select Recipient:</h3>
            <div className="role-buttons">
              <button className="role-button" data-recipient="student">Student</button>
              <button className="role-button" data-recipient="instructor">Instructor</button>
              <button className="role-button" data-recipient="administrator">Administrator</button>
              <button className="role-button" data-recipient="coordinator">Program Coordinator</button>
              <button className="role-button" data-recipient="qa">Quality Assurance Officer</button>
            </div>
          </div>
          <div className="chat-messages" id="chat-messages">
            {/* Chat messages will be displayed here */}
          </div>
          <div className="chat-input">
            <textarea id="message-input" placeholder="Type your message..." rows={3} defaultValue={""} />
            <button id="send-button">Send</button>
          </div>
        </div>
      </div>
      <Link to="/">Go back to Home</Link>
    </div>
  );
};

export default ChatPage;


